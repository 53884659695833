import React, { useState } from 'react';
import Image from 'next/image';

import useMobile from '../components/Hooks/Mobile';

import SideMenu from '../components/SideMenu';
import Footer from '../components/Footer';
import ExtendingPanels from '../components/ExtendingPanels';

import styles from '../styles/Home.module.scss';
import NevoLogo from '../public/assets/images/nevo-advisory-logo.svg'
import Hamburger from '../public/assets/icons/hamburger.svg'


const HomePage = () => {
  const [displayMenu, setDisplayMenu] = useState(false);

  const tablet = useMobile(860)

  const openSideMenu = () => {
    const htmlDoc = document.children[0] as HTMLElement;
    htmlDoc.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    setDisplayMenu(true);
  }

  return (
    <>
      <div className={styles.homePage}>
        <div className={styles.logoContainer}>
          <div className={styles.nevoLogo}>
            <Image layout='responsive' src={NevoLogo} alt="Nevo Advisory" />
          </div>
        </div>

        { tablet && (
          <div className={styles.hamburgerHolder} onClick={openSideMenu}>
            <Image layout='responsive' src={Hamburger} alt="Hamburger icon" />
          </div>
        )}
        
        <h1 className={styles.mainHeader}>
          { tablet 
            ? <span>{"It's Time."}<br/>{"Let's Go Electric."}</span>
            : "It's Time. Let's Go Electric."
          }
        </h1>
      
        <ExtendingPanels tablet={tablet} />
      </div>

      {
        displayMenu && <SideMenu hideMenu={() => setDisplayMenu(false)} />
      }

      <Footer />
    </>   
  )
}
export default HomePage;
