import React from 'react';
import Link from 'next/link';

import SubMenuItems from '../SubMenuItems';
import links from '../../helper/links';
import { 
  whatWeDoItems, qaWhatWeDoItems,
  learnItems, qaLearnItems,
  companyItems, qaCompanyItems, 
  followItems, qaFollowItems, 
} from '../SubMenuItems/menuItems';

import styles from './styles.module.scss'
import eventGTM from '../../lib/gtag';


export default function Footer()  {
  const [WhatWeDoItems]: any = SubMenuItems({ items: whatWeDoItems, qaItems: qaWhatWeDoItems, eventType: 'Footer' });
  const [LearnItems]: any = SubMenuItems({ items: learnItems, qaItems: qaLearnItems, eventType: 'Footer' });
  const [CompanyItems]: any = SubMenuItems({ items: companyItems, qaItems: qaCompanyItems, eventType: 'Footer' });
  const [FollowItems]: any = SubMenuItems({ items: followItems, qaItems: qaFollowItems, eventType: 'Footer' });

  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>

        <div className={styles.footerNavsHolder}>
          <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              What we do
            </h1>
            
            <div>
              { WhatWeDoItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav} onClick={() => eventGTM('telematicsFooter')}>
              <Link href={links.telematics}>Telematics</Link>
            </h1>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav} onClick={() => eventGTM('energyFooter')}>
              <Link href={links.energy}>Energy</Link>
            </h1>
          </div>

          {/* <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              Learn
            </h1>
            
            <div>
              { LearnItems }
            </div>
          </div> */}

          <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              Company
            </h1>

            <div>
              { CompanyItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              Follow
            </h1>
            
            <div>
              { FollowItems }
            </div>
          </div>


          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav + " " + styles.nevoChargingNav}>
              <a onClick={() => eventGTM("nevoieFooter")} href={links.nevo_web} target='_blank' rel='noreferrer'>
                Nevo.ie
              </a>
            </h1>
          </div>
        </div>  


        <div className={styles.policyCopyHolder}>
          <p className='copyright-mark'>© Nevo Advisory 2024</p>
        </div>
      </div>
    </div>
  )
};
