/* eslint-disable @next/next/no-img-element */

import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';

import links from '../../helper/links';

import styles from './styles.module.scss'
import Image1 from '../../public/assets/images/landing-01.jpg';
import Image2 from '../../public/assets/images/landing-02.jpg';
import Image3 from '../../public/assets/images/landing-03.jpg';
import Image4 from '../../public/assets/images/landing-04.jpg';
import ArrowDownIcon from '../../public/assets/icons/arrow-down.svg'

const content = [
  {
    title: "Fleet Transition",
    href: links.fleet_transition,
    image: Image1.src
  },
  {
    title: "Charging Solutions",
    href: links.charging_solutions,
    image: Image2.src
  },
  {
    title: "Energy Management",
    href: links.energy_management,
    image: Image3.src
  },
  {
    title: "E-Mobility Education",
    href: links.emobility_education,
    image: Image4.src
  },
]

interface IExtendingPanels {
  tablet?: boolean;
}

export default function ExtendingPanels({ tablet }: IExtendingPanels)  {
  const [activePanel, setActivePanel] = useState(-1);

  const [width, setWidth] = useState(0);
  const [startScroll, setStartScroll] = useState(0);

  const sweeperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    }

    handleResize()

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  // perform swiping animation
  useEffect(() => {
    if (tablet) {
      let scrollTo = activePanel * width;
  
      if (activePanel < 0) scrollTo = 0;

      sweeperRef?.current?.scrollTo({
        left: scrollTo,
        behavior: 'smooth'
      })
    }
    
  }, [tablet, activePanel, width])

  const onTouchStart = () => {
    if (tablet && sweeperRef?.current) {
      setStartScroll(sweeperRef.current.scrollLeft);
    }
  }
  const onTouchEnd = () => {
    if (tablet && sweeperRef?.current) {
      const endScroll = sweeperRef.current.scrollLeft;

      // swipe right
      if (startScroll < endScroll && !(endScroll > width * (content.length - 1))) {
        nextImage()
      }
      // swipe left
      else if (startScroll > endScroll && !(endScroll < 0)) {
        prevImage()
      }
    }
  }

  const prevImage = () => {
    if (activePanel <= 0) return; 

    if (sweeperRef.current) {
      const prevSlide = Math.round(sweeperRef.current?.scrollLeft / width) - 1;
      if (prevSlide < 0) {
        setActivePanel(0);
        return
      }

      setActivePanel(prevSlide);
    }
  }
  const nextImage = () => {
    if (activePanel >= content.length - 1) return;

    if (sweeperRef.current) {
      const nextSlide = Math.round(sweeperRef.current?.scrollLeft / width) + 1;
      if (nextSlide > content.length - 1) { 
        setActivePanel(content.length - 1);
        return; 
      }

      setActivePanel(nextSlide);
    }
  }

  const renderPanels = () => {
    const shrinkPanel = activePanel >= 0 ? styles.shrinkPanel : ''; 

    return content.map((item, index) => {
      let sweeperClassName = `${styles.sweeperSlide}`

      if (!tablet) {
        sweeperClassName += ` ${activePanel === index ? styles.extended : shrinkPanel}`
      }

      return (
        <Link key={index} href={item.href} passHref>
          <div 
            className={sweeperClassName}
            style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.5)), url(${item.image})` }}
            onMouseEnter={() => {
              if (!tablet) setActivePanel(index);
            }}
            onMouseLeave={() => {
              if (!tablet) setActivePanel(-1);
            }}
          >
            { !tablet && (
              <div className={styles.contentContainer}>
                <div className={styles.textHolder}>
                  <span className={styles.text}>{ item.title }</span>
                  <img src={ArrowDownIcon.src} className={styles.arrowDown} alt="image 1" />
                </div>
              </div>
            )}
          </div>
        </Link>
      )
    })
  }

  return (
    <div className={styles.homeWrapper}>
      <div 
        className={styles.sweeperWrapper} 
        ref={sweeperRef} 
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        { renderPanels() }
      </div>

      { tablet && (
        <div className={styles.controlsHolder}>
          <Link href={content[activePanel < 0 ? 0 : activePanel].href} passHref>
            <h1 className={styles.text}>{ content[activePanel < 0 ? 0 : activePanel].title }</h1>
          </Link>
          
          <div className={styles.arrowsHolder}>
            <div className={styles.arrowHolder}>
              <img
                onClick={prevImage} 
                className={styles.leftArrow + ` ${activePanel <= 0 && styles.disabled}`} 
                src={ArrowDownIcon.src} 
                alt="right arrow"
              />
            </div>

            <div className={styles.arrowHolder}>
              <img 
                onClick={nextImage} 
                className={`${activePanel >= content.length - 1 && styles.disabled}`}
                src={ArrowDownIcon.src} 
                alt="right arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
};
